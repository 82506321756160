import React from 'react'
import { Container } from 'components/PageContainer'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles2 = makeStyles((theme) => ({
  paragraph: {
    textAlign: 'left',
    opacity: 1,
    fontSize: 18,
    lineHeight: '30px',
  },
  headerContainer: {
    padding: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: 25,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '40px 20px 40px 20px',
    },
  },
  responsiveCenter: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      textAlign: 'left',
    },
  },
}))

const TextWhiteBG = () => {
  const classes2 = useStyles2()
  return (
    <div style={{ backgroundColor: 'white' }}>
      <Container>
        <Grid container spacing={0} justify="space-around" alignItems="center">
          <Grid item xs={12} md={6} className={classes2.headerContainer}>
            <p
              style={{ marginBottom: 20 }}
              className={clsx(classes2.paragraph, classes2.responsiveCenter)}
            >
              SwiftDoc provides online video consultations with a doctor using
              your phone or desktop. We SMS e-prescriptions to your phone or
              pharmacy anywhere in Australia. We can even deliver your
              medication for free. We email you medical certificates, referrals
              for blood tests and imaging and send your results to you.
            </p>
            <p className={clsx(classes2.paragraph, classes2.responsiveCenter)}>
              We can also refer you to any specialist, physiotherapist,
              psychologist or allied health professionals. We can also easily
              complete forms and paperwork for you. We also see nursing home
              patients. We can take over your GP care. 18 out of 20 of the most
              common problems seen in General Practice can be managed safely
              through video conferencing. There are some obvious examples of
              conditions that SwiftDoc cannot treat e.g Emergencies. In case of
              an Emergency please call Triple Zero (000)
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default TextWhiteBG
