import Layout from 'components/Layout'
import React from 'react'
import AppStateProvider from '../state'
import Hero from 'components/Hero'
import CoveredConsultations from 'components/CoveredConsultations'
import IllnessList from 'components/IllnessList'
import heroImg from 'assets/images/heroImage.svg'
import TextBlueBG from 'components/TextBlueBG'
import TextWhiteBG from 'components/TextWhiteBG'
function RootIndex() {
  return (
    <AppStateProvider>
      <Layout 
      title="Telehealth Services - Online Doctor, Medical Certificates, Prescriptions | SwiftDoc"
      description="Online Doctors, Medical Cerficates, Prescriptions and more"
      >
        <div className="margin-container">
          <Hero
            heroImg={heroImg}
            title="Experience the evolution of convenient healthcare"
            subTitle="Online doctor consultations using your desktop or phone"
            pageName="OUR SERVICES"
          />
          <TextWhiteBG />
          <TextBlueBG />
          
          <div className="lists" style={{ padding: '150px 0' }}>
            <CoveredConsultations />
            <IllnessList />
          </div>
        </div>
      </Layout>
    </AppStateProvider>
  )
}

export default RootIndex
