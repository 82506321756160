import React from 'react'
import { Container } from 'components/PageContainer'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import DoctorBG from 'assets/images/doctorBg.svg'

const useStyles2 = makeStyles((theme) => ({
  paragraph: {
    opacity: 1,
    fontSize: 18,
    lineHeight:'20px'
  },
  headerContainer: {
    padding: '40px 10px',
    display: 'flex',
    textAlign:'center',
    color:'white',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: 25,
    },
  },
  responsiveCenter: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      textAlign: 'justify',
    },
  },
}))

const texts = [
  "After hours GP services", 
"Prescriptions by SMS to your phone or any pharmacy" ,
"Medical certificates emailed to you", 
"Referrals to specialists emailed to you", 
"Arrange xrays, CTs, MRIs, ultrasounds & blood tests"
]

const TextWhiteBG = () => {
  const classes2 = useStyles2()
  return (
    <div style={{backgroundColor: 'white', backgroundImage:`url(${DoctorBG})`}}>
    <Container>
      <Grid container spacing={0} justify="space-around" alignItems="center">
        <Grid item xs={12} md={12} className={classes2.headerContainer}>
        <h3>Access to everyday doctor and specialist services at your fingertips</h3>
          <p style={{marginBottom:20,marginTop:20}} className={clsx(classes2.paragraph, classes2.responsiveCenter)}>
            No travel hassles or delays         
          </p>
          <div style={{display:'flex',flexDirection:'column', maxWidth:600, alignSelf:'center', marginTop:20}}>
          {texts.map(text => {
            return <TickLine text={text} />
          })}
          </div>

        </Grid>
      </Grid>
    </Container>
    </div>
  )
}

const TickLine = ({text}) => {
  return(
    <div style={{display:'flex', flexDirection:'row',alignItems:'center', margin:'10px 0'}}>
      <svg style={{flexShrink:0}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.1873 13.5401C8.94131 13.5401 8.63115 13.4438 8.46002 13.2727L5.57232 10.385C5.21938 10.032 5.21938 9.4545 5.57232 9.10156C5.92526 8.74862 6.5028 8.74862 6.85575 9.10156L9.14452 11.3903L13.6686 6.86627C14.0215 6.51333 14.5991 6.51333 14.952 6.86627C15.3049 7.21921 15.3049 7.79675 14.952 8.14969L9.82901 13.2727C9.65788 13.4438 9.43329 13.5401 9.1873 13.5401Z" fill="white"/>
<path d="M10 20C4.48128 20 0 15.5187 0 10C0 4.48128 4.48128 0 10 0C15.5187 0 20 4.48128 20 10C20 15.5187 15.5187 20 10 20ZM10 1.81818C5.48663 1.81818 1.81818 5.48663 1.81818 10C1.81818 14.5134 5.48663 18.1818 10 18.1818C14.5134 18.1818 18.1818 14.5134 18.1818 10C18.1818 5.48663 14.5134 1.81818 10 1.81818Z" fill="white"/>
</svg>


        <p style={{marginLeft:10,textAlign:'left'}}>{text}</p>
    </div>
  )
}

export default TextWhiteBG
