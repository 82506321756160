import React from 'react'
import { Container } from '../PageContainer'
import './illnessList.scss'
function IllnessList() {
  const consultations = [
    'Colds, Coughs and Flus',
    'Diarrhoea and Vomiting',
    'Mental Health',
    'UTIs (urine infections)',
    'Sports Injuries',
    'Anxiety',
    'Skin rashes',
    'Allergies',
    'Depression',
    'Sore throats',
    'Quit Smoking',
  ]
  return (
    <Container>
      <div className="boxIllness">
        <div className="boxTitleIllness">
          <span>WE TREAT ANY ILLNESS INCLUDING:</span>
        </div>
        <div className="itemsContainerIllness">
          {consultations.map((item, index) => (
            <div key={index} className="itemIllness">
              <div className="itemTextIllness">{item}</div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default IllnessList
