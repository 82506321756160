import React from 'react'
import { Container } from '../PageContainer'

import arrowBlackIcon from '../../assets/icons/Icon_Arrow_Blue1.svg'
import './coveredConsultations.scss'
function CoveredConsultations() {
  const consultations = [
    'Repeat Prescriptions',
    'Blood Tests',
    'Medical Certificates',
    'Sexual Health Check-ups',
    'Weight Loss',
    'Dietician Consultations',
    'Repeat Prescriptions',
    'Hair Loss Clinic',
    'Mental Health Counselling & Psychology',
    'Travel Vaccinations',
    'After-hours GP appointments',
    'Completing forms for you',
    'Delivery of Medication',
    'Occupational Health Services',
    'General health advice',
    'Second Opinions',
    'Imaging referrals',
    'Referrals to Specialists',
  ]
  return (
    <Container>
      <h1 className="title">Our Online Doctor Consultations Cover:</h1>
      <div className="box">
        
        <div className="itemsContainer">
          {consultations.map((item, index) => (
            <div key={index} className="item">
              <div className="itemArrow">
                <img src={arrowBlackIcon} alt="arrow icon" />
              </div>
              <div className="itemText">{item}</div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default CoveredConsultations
